/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/* nunito-sans-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: 300;
  src: url('/extras/fonts/nunito-sans-v15-latin-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-sans-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: 500;
  src: url('/extras/fonts/nunito-sans-v15-latin-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* nunito-sans-800 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: 800;
  src: url('/extras/fonts/nunito-sans-v15-latin-800.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* gloock-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Gloock';
  font-style: normal;
  font-weight: 300;
  src: url('/extras/fonts/gloock-v6-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.unit > .body,
.unit > form > .body {
  margin: 0 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0;
  margin-bottom: 0;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 5px;
  margin-bottom: 5px;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 2.5px !important;
  margin-bottom: 2.5px !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #4f5960;
  color: #4f5960;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #4f5960 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #b3b3b3;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 8px 0;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: 500;
  background: #ccc;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #e6e6e6;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #4f5960;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #4f5960;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: 500;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #4f5960;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #001f3f;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #d3d8db;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #002C58;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #002C58;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 1024px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #e6e6e6;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 1px 4px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: 500;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 1px 4px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 1px 4px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 44, 88, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 14px;
  font-size: 1.4rem;
  width: 100%;
  background-color: transparent;
  color: #4f5960;
  font-family: 'NunitoSans', helvetica, sans-serif;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}
body.cb-layout3 {
  color: #7c9087;
}
.desk {
  *zoom: 1;
  position: relative;
  margin: 0 auto;
  width: 100%;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
#home {
  float: left;
}
.cb-layout3 #home {
  height: auto;
  width: 172px;
  aspect-ratio: 1.4875;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/youngMinds.svg);
  margin-right: 32px;
  margin-top: 134px;
}
.logo {
  width: 100%;
  height: auto;
}
.cb-layout3 .logo {
  display: none;
}
#head {
  float: left;
  width: 100%;
}
.cb-layout3 #slogan {
  display: none;
}
#wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}
.section {
  position: relative;
  float: left;
  width: 100%;
}
.section.section--side {
  position: fixed;
  background-color: #fff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
}
.section.section--main {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.container {
  position: relative;
  float: left;
  width: 100%;
}
.container.container--main {
  flex: 1 1 auto;
  background: #D8DDE7;
}
.cb-layout3 .container.container--main {
  background-color: transparent;
}
.container.container--footer {
  background: #002C58;
  color: #fff;
}
.cb-layout3 .container.container--footer {
  background-color: #7c9087;
}
.container.container--footer a {
  color: #fff;
}
.container.container--footer a:hover,
.container.container--footer a:focus {
  color: rgba(255, 255, 255, 0.4);
}
.container.container--footer #social .meta {
  float: left;
  box-sizing: border-box;
  display: inline-block;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
@media only screen and (min-width: 1025px) {
  .container.container--footer #social .meta {
    width: 45px;
    height: 45px;
  }
}
.container.container--footer #social .meta:hover,
.container.container--footer #social .meta:focus {
  opacity: 0.6;
}
.container.container--footer #social .meta.service_social-xing {
  background: #b0d400 url(/images/icon-xing.svg) no-repeat center / 13px 17px;
}
@media only screen and (min-width: 1025px) {
  .container.container--footer #social .meta.service_social-xing {
    background-size: 17px 20px;
  }
}
.container.container--footer #social .meta.service_social-linkedin {
  background: rgba(255, 255, 255, 0.4) url(/images/icon-linkedin.svg) no-repeat center / 11px 12px;
  position: relative;
  overflow: visible;
}
.container.container--footer #social .meta.service_social-linkedin:after {
  content: 'Folgen Sie uns auf LinkedIn';
  position: absolute;
  left: 100%;
  top: 50%;
  margin-left: 10px;
  text-indent: 0;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 1.25;
  color: #fff;
}
@media only screen and (min-width: 1025px) {
  .container.container--footer #social .meta.service_social-linkedin {
    background-size: 15px 14px;
  }
}
.container.container--footer #social .meta.print {
  background: url(/images/icon-print.svg) no-repeat center / 28px 26px;
}
@media only screen and (min-width: 1025px) {
  .container.container--footer #social .meta.print {
    background-size: 35px 31px;
  }
}
.cb-layout3 .container--south {
  background-color: #7c9087;
  color: #fff;
}
#cb-cookie-warning {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  background-color: #002C58;
  box-shadow: 0 0 10px rgba(60, 60, 60, 0.6);
  box-sizing: border-box;
  padding-left: 22.33333333%;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  line-height: 1.25;
}
#cb-cookie-warning a {
  color: #fff;
  text-decoration: underline;
}
#cb-cookie-warning a:hover,
#cb-cookie-warning a:focus {
  color: #ccd5de;
}
@media (max-width: 1023px) {
  #cb-cookie-warning {
    padding-left: 0;
  }
}
.cb-cookie-warning--container {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
@media (max-width: 767px) {
  .cb-cookie-warning--container {
    flex-direction: column;
    text-align: center;
    font-size: 14px;
    line-height: 1.28571429;
  }
}
.cb-cookie-warning--text {
  float: left;
}
.cb-cookie-warning--text a {
  display: inline-block;
  margin-left: 6px;
}
.cb-cookie-warning--actions {
  float: left;
  margin-left: 20px;
}
.cb-cookie-warning--actions button {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
  white-space: nowrap;
}
.cb-cookie-warning--actions button:hover,
.cb-cookie-warning--actions button:focus {
  color: #ccd5de;
}
@media (max-width: 767px) {
  .cb-cookie-warning--actions {
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
  }
}
#cb-cookie-warning__button--decline {
  display: none;
}
.footcontent {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.kundenversprechen {
  position: absolute;
  right: 0;
  top: 0;
  width: 130px;
  z-index: 2;
}
@media (max-width: 1173px) {
  .kundenversprechen {
    right: -40px;
    width: 100px;
  }
}
@media (max-width: 1023px) {
  .kundenversprechen {
    right: 0;
    width: 120px;
  }
}
.circle {
  float: left;
  width: calc(100% + 20px);
  margin-left: -10px;
  display: none;
}
.cb-layout3 .circle {
  display: block;
}
h1 {
  color: #002C58;
  font-weight: 800;
}
.cb-layout3 h1 {
  color: #7c9087;
  font-family: 'Gloock', serif;
}
h2 {
  color: #6c7a84;
  font-weight: 400;
}
.cb-layout3 h2 {
  font-family: 'Gloock', serif;
}
.cb-layout3 .south h2 {
  color: #fff;
}
h3 {
  color: #3c434d;
}
strong {
  font-weight: 500;
  color: #2d3338;
}
.cb-layout3 strong {
  color: #7c9087;
}
.loud {
  display: block;
  background-color: #d6ccc3;
  box-sizing: border-box;
  color: #fff;
  font-weight: 500;
  padding: 15px 30px;
}
.pale {
  color: #3c434d;
  font-weight: 500;
}
.skew {
  color: #6c7a84;
  font-style: italic;
}
.text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.gist,
.dent {
  list-style: disc;
}
a {
  color: #002C58;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.218s;
}
a:hover,
a:focus {
  color: #8396AD;
}
.cb-layout3 a {
  color: inherit;
}
.cb-layout3 a:hover,
.cb-layout3 a:focus {
  opacity: 0.7;
}
.area.south {
  text-align: center;
}
.cb-layout1 .area.south .unit.pure,
.cb-layout3 .area.south .unit.pure {
  text-align: left;
}
.area .unit.fold {
  position: relative;
  margin-top: -2px;
  margin-bottom: 0;
  border: 2px solid #fff;
}
.area .unit.fold.fold--closed .foot .ctrl .less {
  opacity: 0;
}
.area .unit.fold.fold--closed .foot .ctrl .more {
  opacity: 1;
}
.area .unit.fold:not(.fold--closed) {
  background-color: #fff;
}
.area .unit.fold:not(.fold--closed) .foot .ctrl .less {
  opacity: 1;
}
.area .unit.fold:not(.fold--closed) .foot .ctrl .more {
  opacity: 0;
}
.area .unit.fold .less .part {
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
}
.area .unit.fold .more {
  display: block !important;
  max-height: 1000px;
  overflow: hidden;
  width: 100%;
  transition: max-height 0.4s ease-in;
}
.area .unit.fold .more .part {
  box-sizing: border-box;
  transform: 0;
  transition: transform 0.6s, opacity 0.3s 0.2s;
}
.area .unit.fold .more.cb-invisible {
  max-height: 0;
  transition-delay: 0.1s;
}
.area .unit.fold .more.cb-invisible > .part {
  opacity: 0;
  transform: translateY(-100px);
}
.area .unit.fold .part {
  display: block;
  margin-right: 0;
  margin-left: 0;
}
.area .unit.fold .foot .ctrl {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
}
.area .unit.fold .foot .ctrl .less,
.area .unit.fold .foot .ctrl .more {
  display: block;
  border: none;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
.area .unit.fold .foot .ctrl .less {
  background: url(/images/fold-less.svg) no-repeat right 30px center;
  background-size: 12px 7px;
}
.area .unit.fold .foot .ctrl .more {
  background: url(/images/fold-more.svg) no-repeat right 30px center;
  background-size: 12px 7px;
}
.area .unit.edge {
  background: #fff;
}
.area .unit.form .cb-form-required,
.area .unit.form fieldset .name,
.area .unit.form label.sign {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.42857143;
}
.area .unit.form fieldset .name {
  font-weight: 500;
}
.area .unit.form input.text,
.area .unit.form input.sign,
.area .unit.form input.password,
.area .unit.form textarea,
.area .unit.form input.hint,
.area .unit.form textarea.hint,
.area .unit.form input.capt {
  padding: 8px 15px;
  border-radius: 0;
  box-shadow: none;
}
.area .unit.form .pile .ctrl div {
  height: 20px;
}
.area .unit.form input.submit,
.area .unit.form button.cb-hybrid {
  float: left;
  margin-top: 22px;
  transition: all 0.218s;
}
.cb-layout1 div.main div.flat div.text a,
.cb-layout3 div.main div.flat div.text a {
  padding-left: 30px;
  background-size: 22px 16px;
  background-position: 0 50%;
  background-repeat: no-repeat;
  transition: none;
}
.cb-layout1 div.main div.flat div.text a[title*='@'],
.cb-layout3 div.main div.flat div.text a[title*='@'] {
  background-image: url(/images/icon-mail-white.svg);
}
.cb-layout1 div.main div.flat div.text a[href*='tel:'],
.cb-layout3 div.main div.flat div.text a[href*='tel:'] {
  background-image: url(/images/icon-phone-white.svg);
}
.cb-layout1 div.main div.flat div.text a[title*='linkedin'],
.cb-layout3 div.main div.flat div.text a[title*='linkedin'] {
  background-image: url(/images/icon-linkedin-white.svg);
}
.cb-layout3 .area.main {
  padding-bottom: 220px;
  background-size: 266px auto;
  background-position: right 15px bottom 20px;
  background-repeat: no-repeat;
  background-image: url(/images/bubbles1.svg);
}
.cb-layout3 .area.south {
  text-align: left;
}
.cb-layout3 .area.south .edge {
  background-color: rgba(255, 255, 255, 0.4);
}
.cb-layout3 .area.areaTen {
  margin-top: 60px;
  padding-bottom: 260px;
  background-size: 200px auto;
  background-position: left 15px bottom 30px;
  background-repeat: no-repeat;
  background-image: url(/images/bubbles2.svg);
}
.cb-layout3 .area .edge {
  background-color: rgba(124, 144, 135, 0.2);
}
.cb-layout3 .area .edge .part.tall {
  float: right;
}
.link {
  background: url('/icon-link/link.gif') no-repeat 0 0.4em;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.5em;
}
.load {
  background-image: none!important;
}
.load .load {
  position: relative;
  margin-left: 0;
  padding-left: 45px;
}
.load .load:before {
  content: "PDF";
  position: absolute;
  top: calc(50% - 9px);
  left: 0;
  width: 35px;
  height: 19px;
  border-radius: 4px;
  background-color: #e84c3d;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.58333333;
  text-align: center;
}
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
.area .part.text h2 {
  margin-right: 0!important;
  margin-left: 0!important;
}
.area .part.text > * {
  display: block;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.area .part.text > h2,
.area .part.text > h3 {
  padding-top: 0.9em;
  padding-bottom: 0;
}
.area .part.text > *:first-child {
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.area .part.text > *:last-child {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.area .part.text ul + h2,
.area .part.text ul + h3 {
  padding-top: 0;
}
.area .part.text ul.list {
  padding-left: 0;
  list-style-type: none;
}
.area .part.text ul.list li {
  padding-left: 25px;
  position: relative;
}
.area .part.text ul.list li:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 8px;
  height: 8px;
  background-color: #002C58;
  border-radius: 50%;
}
.area .part.cb-googlemaps {
  border: 2px solid #fff;
}
.area .part.table table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.area .part.table table tbody tr {
  border-top: 1px solid #6c7a84;
}
.area .part.table table tbody tr:last-child {
  border-bottom: 1px solid #6c7a84;
}
.area .part.table table tbody tr td {
  padding: 8px 0;
  border-width: 0;
  border-style: solid;
  border-color: #b3b3b3;
  vertical-align: top;
}
.area .part.table table tbody tr td.odd {
  background: #e6e6e6;
}
.area .unit.flat .part.text {
  background: rgba(0, 44, 88, 0.9);
  color: #fff;
}
.area .unit.flat .part.text * {
  color: #fff;
}
.cb-layout3 .area .unit.flat .part.text {
  background: rgba(214, 204, 195, 0.8);
}
.cb-layout3 .area .unit.flat .part.text ul.list li:before {
  background-color: #fff;
}
.area .unit.seam.slim .part.text p:first-child {
  background: rgba(0, 44, 88, 0.9);
  color: #fff;
}
div.part.link {
  background: none !important;
}
div.part.link a.open {
  margin-left: 0;
  float: left;
}
.vcard {
  float: right;
  margin-top: 20px;
  text-align: right;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.57142857;
}
.vcard a {
  color: #fff;
  font-weight: 300;
}
.vcard .org,
.vcard .adr {
  display: block;
}
@media only screen and (min-width: 1025px) {
  .vcard {
    position: absolute;
    top: 50px;
    right: 0;
    margin-top: 0;
  }
}
/*img {
  filter: grayscale(100%);
  &:hover,
  &:focus {
    filter: grayscale(0%);
  }
}*/
.grow {
  text-transform: none !important;
}
#edit .container.container--north {
  z-index: 20;
}
#edit #expo div.link .cb-index {
  display: none;
}
#edit .area {
  min-height: 30px;
}
#edit .area .unit {
  min-height: 50px;
}
#edit .area .unit.fold .foot .ctrl {
  width: 24px;
}
#edit .area .unit.fold .foot .ctrl .less,
#edit .area .unit.fold .foot .ctrl .more {
  background-position: right center;
}
#edit .area .unit.flat .part.text {
  position: relative;
  top: 0;
}
#edit .area .unit.seam .part.text {
  position: relative;
  top: 0;
}
#edit .area .unit.edge.slim .part {
  position: relative;
}
#edit .area .unit.edge.slim .part.link .open {
  position: relative;
  font-size: 14px;
  font-size: 1.4rem;
  text-indent: 0;
}
#edit .area .unit.edge.slim .part.pict {
  filter: none;
}
#edit .area .unit.edge.slim .part.pict:first-child {
  filter: none;
}
#edit .area .unit.edge.slim:hover .part:nth-child(2),
#edit .area .unit.edge.slim:focus .part:nth-child(2) {
  opacity: 1;
}
.text-section {
  float: left;
  width: 100%;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #002C58;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #002C58;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.6em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #222222;
  background-color: #fff;
  font-weight: normal;
  font-family: 'NunitoSans', helvetica, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 1.6em;
  border: 1px solid #d4d4d4;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 14px;
  font-size: 1.4rem;
  color: #444;
  font-weight: normal;
  font-family: 'NunitoSans', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #6f6f6f;
}
input.text[disabled],
textarea[disabled] {
  border-color: #e1e1e1;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #002C58;
  -webkit-box-shadow: 0 0 3px rgba(0, 44, 88, 0.7);
  box-shadow: 0 0 3px rgba(0, 44, 88, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #004b95;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #004b95;
}
input.submit,
button.cb-hybrid {
  display: inline-block;
  background-color: #002C58;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 35px;
  -webkit-appearance: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  text-transform: uppercase;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  color: #fff;
  background-color: #8396AD;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  background-color: #002C58;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: 0 0.3em 0 0;
  min-height: 1.6em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  float: left;
  width: 100%;
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 40.26666667%;
  position: relative;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 150px;
  height: 100%;
  transform: translateY(-50%);
  background: #002C58;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
#expo a.link.prev {
  left: 0;
  background: url(/images/mood-prev.svg) no-repeat center;
}
#expo a.link.next {
  right: 0;
  background: url(/images/mood-next.svg) no-repeat center;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  bottom: 30px;
  z-index: 3;
  width: 870px;
  max-width: 74.67811159%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 5px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 10px;
  height: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
  opacity: 0.5;
}
.cb-index-all td.this a {
  opacity: 1;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  display: none;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
/*** mobile-navi-multilevel-right-left.less 2014-11-03 ***/
.navi,
#find {
  display: none;
}
#mobile-navi {
  position: fixed;
  box-sizing: border-box;
  top: 74px;
  width: 100%;
  height: 100%;
  padding: 0 4.6875%;
  z-index: 210;
  overflow: scroll;
  background: #002C58;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
@media only screen and (min-width: 1025px) {
  #mobile-navi {
    top: 0;
    width: 77.66666667%;
  }
}
.togglenavigation {
  position: absolute;
  top: 20px;
  display: block;
  width: 16px;
  height: 20px;
  padding: 7px 55px 7px 14px;
  border: 1px solid #002C58;
  border-radius: 4px;
  z-index: 221;
  cursor: pointer;
  color: #002C58 !important;
  -webkit-tap-highlight-color: transparent;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  -webkit-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
}
.togglenavigation:active > span {
  background: #00182f !important;
}
.togglenavigation:after {
  content: "Menu";
  position: absolute;
  top: 0;
  left: 35px;
  color: #002C58;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 2.76923077;
  text-transform: uppercase;
  mtransition: all 0.218s;
}
.togglenavigation > span {
  left: 14px;
  top: 50%;
  position: absolute;
  display: block;
  height: 3px;
  width: 16px;
  margin-top: -2px;
  border-radius: 2px;
  background: currentColor;
}
.togglenavigation > .tline-1 {
  margin-top: -8px;
}
.togglenavigation > .tline-4 {
  margin-top: 4px;
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  -webkit-transition: -webkit-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -o-transition: -o-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -moz-transition: -moz-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media only screen and (min-width: 1025px) {
  .togglenavigation {
    top: 30px;
    width: 21px;
    padding: 11px 79px 11px 19px;
  }
  .togglenavigation:after {
    left: 47px;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 2.44444444;
  }
  .togglenavigation > span {
    left: 19px;
    height: 4px;
    width: 21px;
  }
  .togglenavigation > .tline-1 {
    margin-top: -10px;
  }
  .togglenavigation > .tline-4 {
    margin-top: 6px;
  }
}
body.cb-toggle-target-active #mobile-navi {
  -o-transition: all 0.218s ease-out;
  -moz-transition: all 0.218s ease-out;
  -webkit-transition: all 0.218s ease-out;
  transition: all 0.218s ease-out;
}
body.cb-toggle-target-active .togglenavigation {
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
  -webkit-transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
  transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
}
@media only screen and (max-width: 1024px) {
  /* RIGHT */
  #mobile-navi {
    right: -100%;
  }
  .togglenavigation {
    right: 4.6875%;
  }
  body.cb-toggle-target-active #mobile-navi {
    right: 0;
  }
}
@media only screen and (min-width: 1025px) {
  /* LEFT */
  #mobile-navi {
    left: -77.66666667%;
  }
  .togglenavigation {
    right: 7.46268657%;
  }
  body.cb-toggle-target-active #mobile-navi {
    left: 22.33333333%;
  }
}
#mobile-navi .navi {
  float: left;
  display: block;
  width: 100%;
}
#mobile-navi .navi.sub1 {
  margin-top: 20px;
}
#mobile-navi .navi .item {
  width: 100%;
  float: left;
  position: relative;
  border-bottom: 1px solid #fff;
}
#mobile-navi .navi .item.init {
  border-top: 1px solid #fff;
}
#mobile-navi .navi .item .menu {
  display: block;
  color: #fff;
  padding: 9px 0;
  padding-right: 37px;
  font-size: 16px;
}
#mobile-navi .navi .item .menu:hover,
#mobile-navi .navi .item .menu:focus {
  color: #8396AD;
}
#mobile-navi .navi .item .menu.path {
  color: #8396AD;
}
@media only screen and (min-width: 1025px) {
  #mobile-navi .navi.sub1 {
    margin-top: 135px;
  }
  #mobile-navi .navi .item {
    border-bottom-width: 2px;
  }
  #mobile-navi .navi .item.init {
    border-top-width: 2px;
  }
  #mobile-navi .navi .item .menu {
    padding: 11px 2px;
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.5;
  }
}
#mobile-navi .navi.sub2 {
  max-height: 0;
  overflow: hidden;
}
#mobile-navi .navi.sub2 .item {
  border: none;
}
#mobile-navi .navi.sub2 .item .menu {
  font-size: 14px;
  font-weight: 300;
  padding-left: 10px;
}
@media only screen and (min-width: 1025px) {
  #mobile-navi .navi.sub2 .item .menu {
    padding-left: 15px;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.22222222;
  }
}
#mobile-navi div.navi > .item.cb-toggle-target-active > div.navi {
  max-height: 1000px;
  -o-transition: all 2s;
  -moz-transition: all 2s;
  -webkit-transition: all 2s;
  transition: all 2s;
}
#mobile-navi .cb-toggle {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  width: 37px;
  height: 37px;
  background: url(/images/plus-white.svg) no-repeat right center / 11px;
}
#mobile-navi .cb-toggle:hover,
#mobile-navi .cb-toggle:focus {
  cursor: pointer;
}
#mobile-navi .cb-toggle.cb-toggle-active {
  background: url(/images/minus-white.svg) no-repeat right center / 11px;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
@media only screen and (min-width: 1025px) {
  #mobile-navi .cb-toggle {
    width: 52px;
    height: 52px;
    background-position: center;
  }
  #mobile-navi .cb-toggle.cb-toggle-active {
    background-position: center;
  }
}
#mobile-navi div.sub2 .cb-toggle {
  height: 34px;
}
#mobile-navi .navi > .cb-toggle,
#mobile-navi .navi .item-empty > .cb-toggle {
  display: none;
}
/******* layout-small.less 2013-1-16 *******/
@media only screen and (max-width: 768px) {
  #home {
    width: 168px;
    height: 64px;
    margin-top: 12px;
    margin-left: 13px;
  }
  .cb-layout3 #home {
    margin-top: 10px;
    width: 80px;
    margin-right: 0;
  }
  #head {
    width: 90.625%;
    margin-top: 35px;
    margin-left: 4.6875%;
  }
  #slogan {
    display: none;
  }
}
.section.section--side {
  min-height: 74px;
  z-index: 220;
}
.section.section--main {
  margin-top: 74px;
}
.container.container--mood {
  display: none;
}
.container.container--footer {
  width: 90.625%;
  padding: 16px 4.6875%;
}
.container.container--footer #footerNav .meta {
  display: block;
  margin: 5px 30px 5px 0;
}
.container.container--footer #social {
  margin-top: 8px;
}
.container.container--footer #social .meta {
  margin-right: 16px;
}
.container.container--footer #services {
  float: left;
  margin-top: 10px;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 4.6875%;
  margin-left: 4.6875%;
}
.area .part,
.area > .grid table {
  margin-right: 4.6875%;
  margin-left: 4.6875%;
  width: 90.625%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 90.625%;
}
.area .unit .part.table {
  margin-top: 0;
  margin-bottom: 0;
}
.area .unit.edge {
  margin-top: 15px;
  margin-bottom: 15px;
}
.area .unit.edge .part {
  margin-top: 15px;
  margin-bottom: 15px;
}
.area.main {
  margin-bottom: 15px;
}
.area.main:empty {
  margin-bottom: 0;
}
.area.main .unit.wide:first-child {
  margin-top: 5px;
}
.area.south {
  margin-top: 15px;
  margin-bottom: 10px;
}
.area.south:empty {
  margin-top: 0;
  margin-bottom: 0;
}
.area .unit.fold .body .less .part {
  padding: 16px 4.6875%;
}
.area .unit.fold .body .more .part:last-child {
  margin-bottom: 15px;
}
.area .unit.fold .part {
  width: 90.625%;
  padding-right: 4.6875%;
  padding-left: 4.6875%;
}
.area .unit.fold .foot .ctrl .less,
.area .unit.fold .foot .ctrl .more {
  height: 22px;
  padding: 16px 0;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
@media only screen and (max-width: 768px) {
  .area .unit.fold {
    border-right: none;
    border-left: none;
  }
  .area .unit.flat .part + .part {
    margin-top: -5px;
  }
  .area .unit.flat .part.text {
    box-sizing: border-box;
    background: #002C58;
    padding: 15px;
  }
}
.area .unit.seam.slim .part + .part {
  margin-top: -57px;
}
.area .unit.seam.slim .part.text p:first-child {
  box-sizing: border-box;
  padding: 15px 20px;
  padding-top: 15px!important;
  transition: all 0.218s;
}
.area .unit.seam.slim .part.text p:nth-child(2) {
  padding-top: 1.1em;
}
.area .unit.edge {
  position: relative;
}
.area .unit.edge.wide {
  width: 90.625%;
  margin-right: 4.6875%;
  margin-left: 4.6875%;
  padding-top: 15px;
  padding-bottom: 15px;
}
.area .unit.edge.wide .part {
  width: 82.75862069%;
  margin-right: 8.62068966%;
  margin-left: 8.62068966%;
}
.area .unit.edge.wide .part.tiny.pict img {
  margin: 0 auto;
}
.area .unit.edge.slim {
  width: 90.625%;
  margin-right: 4.6875%;
  margin-left: 4.6875%;
}
.area .unit.edge.slim .body {
  display: flex;
}
.area .unit.edge.slim .part {
  width: 82.75862069%;
  margin-right: 8.62068966%;
  margin-left: 8.62068966%;
}
.area .unit.edge.slim .part.pict {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.218s;
}
.area .unit.edge.slim .part.pict:nth-child(2) {
  display: none;
}
.area .unit.edge.slim .part.link {
  width: 100%;
  margin: 0;
  background: none;
}
.area .unit.edge.slim .part.link .open {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-left: 0;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
.ie10 .area .unit.edge.slim .part.pict img,
.ie11 .area .unit.edge.slim .part.pict img {
  height: 100%;
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
h1,
h2 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
h1 {
  font-size: 29px;
  font-size: 2.9rem;
  line-height: 1.2;
}
.area h2 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}
.cb-layout3 .area h2 {
  font-size: 29px;
  font-size: 2.9rem;
  line-height: 1.2;
}
.area h3 {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.5;
}
.pale {
  font-size: 10px;
  font-size: 1rem;
  line-height: 1.3;
}
.container.container--footer {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 2.23076923;
}
.container.container--footer #services {
  font-size: 11px;
  font-size: 1.1rem;
  line-height: 2.63636364;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table tr {
  border-top: 1px solid #6c7a84;
  border-bottom: 1px solid #6c7a84;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 0;
}
.table--headline th {
  font-weight: 500;
}
.table td {
  padding: 5px 0;
}
.table--footer .table-foot td {
  font-weight: 500;
}
@media (max-width: 767px) {
  .table {
    font-size: 1.82529335vw;
  }
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
/*# sourceMappingURL=./screen-small.css.map */